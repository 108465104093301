import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';
import user from '../assets/user.png';

class Profile extends Component {

    onSubmit = async event => {
        event.preventDefault();

        /*  Axios.post('http://localhost:3001/profile', {
              pr_Address: tokenBuyingAccounts[0],
              pr_Tokens: presaleTokens,
              pr_referrer: this.state.presale_referrer,
              pr_Ref_Tokens: this.state.presaleRefTokens,
              pr_BNB_Value: buyingValueToDB
               }).then(() => {
                 alert("Successful Insert");
               });
  */

    }


    render() {

        return (

            <div class="main">
                <div class="line"></div>
                
                    <form class="form" action="http://localhost:3001/profile" method="post" type="multipart/form-data">
                    <div class="block_container">

                        <div class="user">
                            <div><img class="userPic" src={user} /></div>
                            <input type="text" class="form__input" name="yourName" placeholder="Your name" />
                        </div>

                        <textarea id="w3review" name="story" rows="10" cols="80" class="comment" placeholder="You can write your story here...">

                        </textarea>

                        <div>
                            <button class="button" type="submit">Submit</button>
                        </div>
                    </div>
                </form>

            </div>)
    }
}

export default Profile;

